<template>
  <div class="social-share-container d-flex align-space-between">
    <a @click="shareOnFacebook" class="share-icon">
      <img src="/img_layout/social_icons/facebook.svg" alt="Facebook" />
    </a>
    <a @click="shareOnInstagram" class="share-icon">
      <img src="/img_layout/social_icons/instagram.svg" alt="Instagram" />
    </a>
    <a @click="shareOnTwitter" class="share-icon">
      <img src="/img_layout/social_icons/x.svg" alt="Twitter" />
    </a>
    <a @click="shareOnLinkedIn" class="share-icon">
      <img src="/img_layout/social_icons/linkedIn.svg" alt="LinkedIn" />
    </a>
  </div>
</template>
<style>
.social-share-container {
  gap: 10px;
  .share-icon {
    display: flex;
    cursor: pointer;
  }
}
</style>
<script>
export default {
  props: {
    shareTitle: { type: String, required: false },
    shareDescription: { type: String, required: false }
  },
  data() {
    return {
      websiteURL: window.location.href
    };
  },
  methods: {
    shareOnFacebook() {
      const shareURL = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
        this.websiteURL
      )}`;
      window.open(shareURL, "_blank");
    },
    shareOnInstagram() {
      // Instagram doesn't support direct sharing via URL, so you can open your Instagram profile in a new tab/window.
      window.open("https://www.instagram.com/", "_blank");
    },
    shareOnTwitter() {
      const shareURL = `https://twitter.com/intent/tweet?url=${encodeURIComponent(
        this.websiteURL
      )}&text=${encodeURIComponent(this.shareTitle)}`;
      window.open(shareURL, "_blank");
    },
    shareOnLinkedIn() {
      const shareURL = `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(
        this.websiteURL
      )}&title=${encodeURIComponent(
        this.shareTitle
      )}&summary=${encodeURIComponent(
        this.shareDescription
      )}&source=${encodeURIComponent(this.websiteURL)}`;
      window.open(shareURL, "_blank");
    }
  }
};
</script>
