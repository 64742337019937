<template>
  <div
    class="ali-timeline-wrapper"
    :class="`ali-timeline-${color}`"
    ref="ali-time-line"
  >
    <v-tabs slider-size="5" show-arrows class="ali-timeline-tabs">
      <v-tabs-slider color="transparent"></v-tabs-slider>
      <v-tab
        :ripple="false"
        v-for="(item, index) in contentParsed"
        :key="index"
        class="ali-timeline-tab"
        @click="updateColorStyles"
      >
        {{ item.title }}
      </v-tab>
      <v-tab-item
        touch
        show-arrows="true"
        show-arrows-on-hover="true"
        v-for="(item, index) in contentParsed"
        :key="index"
        class="ali-timeline-tab-item"
      >
        <v-row
          class="d-flex flex-column-reverse flex-md-row align-center justify-center pa-4"
        >
          <v-col
            cols="12"
            md="3"
            class="ali-timeline-img  d-flex justify-center"
          >
            <div class="ali-timeline-img-container rounded-pill">
              <img
                :src="item.image"
                alt="Image"
                class="w-100 h-100 pa-2 wpimgfix"
              />
            </div>
          </v-col>
          <v-col cols="12" md="9" class="ali-timeline-tab-content">
            <h3 class="heading" :class="`${color}--text`">{{ item.title }}</h3>
            <p v-html="item.text"></p>
          </v-col>
        </v-row>
      </v-tab-item>
    </v-tabs>
  </div>
</template>
<style lang="scss">
.ali-timeline-green {
  .ali-timeline-tabs {
    div.v-tabs-bar .v-tabs-bar__content::before {
      background-color: var(--v-green-base);
    }
  }
}
.ali-timeline-yellow {
  .ali-timeline-tabs {
    div.v-tabs-bar .v-tabs-bar__content::before {
      background-color: var(--v-yellow-base);
    }
  }
}
.ali-timeline-wrapper {
  background-color: transparent;
  .ali-timeline-tabs {
    background-color: transparent;
    div.v-tabs-bar {
      height: unset !important;
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      .v-slide-group__next,
      .v-slide-group__prev {
        background: #fbfaf8;
        color: #000000;
        font-weight: bold;
        border: 14px solid white;
        border-radius: 50%;
        width: 100px;
        height: 100px;
        min-width: 100px !important;
        min-height: 100px !important;
        box-shadow: 0 0.8rem 2.5rem rgba(0, 0, 0, 0.175) !important;
        margin: 8px;
        i {
          font-size: 3rem;
        }
      }
      .v-slide-group__wrapper {
        .v-slide-group__content {
          align-items: center;
          padding: 32px 0 32px 0;
        }
      }
      .v-slide-group__prev--disabled,
      .v-slide-group__next--disabled {
        display: none;
      }
    }
    div.v-tabs-bar .v-tabs-bar__content::before {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      margin: auto;
      top: 50%;
      width: 100%;
      height: 2px;
      // background-color: var(--v-green-base);
      z-index: -1;
    }
    .v-tabs-items,
    .v-tabs-bar {
      background: transparent !important;
    }
    .ali-timeline-tab {
      // background: var(--v-green-lighten2);
      font-size: 1.4rem;
      color: #000000;
      font-weight: bold;
      border: 14px solid white;
      border-radius: 50%;
      width: 100px;
      height: 100px;
      box-shadow: 0 0.8rem 2.5rem rgba(0, 0, 0, 0.175) !important;
      margin: 8px;
    }
    .ali-timeline-tab.v-tab--active {
      // background: var(--v-green-base);
    }
    .ali-timeline-tab-item {
      .ali-timeline-img {
        .ali-timeline-img-container {
          width: 200px;
          height: 200px;
          img {
            border-radius: 50%;
            object-fit: cover;
            object-position: center;
          }
        }
      }
      .ali-timeline-tab-content {
        background-color: transparent;
        .heading {
          font-size: 2rem;
        }
      }
    }
    .ali-timeline-tab:before {
      background-color: unset;
    }
    .ali-timeline-tab.v-tab--active {
      font-size: 2rem;
      width: 150px;
      height: 150px;
      border-width: 18px;
    }
  }
}
@media #{map-get($display-breakpoints, 'sm-and-down')} {
  .ali-timeline-wrapper {
    .ali-timeline-tabs {
      div.v-tabs-bar {
        .v-slide-group__next,
        .v-slide-group__prev {
          background: #fbfaf8;
          color: #000000;
          font-weight: bold;
          border: 4px solid white;
          border-radius: 50%;
          width: 32px;
          height: 32px;
          min-width: 32px !important;
          min-height: 32px !important;
          box-shadow: 0 0.8rem 2.5rem rgba(0, 0, 0, 0.175) !important;
          margin: 6px;
          i {
            font-size: 1rem;
          }
        }
        .v-slide-group__wrapper {
          .v-slide-group__content {
          }
        }
        .v-slide-group__prev--disabled,
        .v-slide-group__next--disabled {
        }
      }
      div.v-tabs-bar .v-tabs-bar__content::before {
      }
      .v-tabs-items,
      .v-tabs-bar {
      }
      .ali-timeline-tab {
        // background: var(--v-green-lighten2);
        font-size: 1.4rem;
        color: #000000;
        font-weight: bold;
        border: 6px solid white;
        border-radius: 50%;
        width: 75px;
        height: 75px;
        max-width: 75px;
        max-height: 75px;
        min-width: 75px;
        min-height: 75px;
        box-shadow: 0 0.8rem 2.5rem rgba(0, 0, 0, 0.175) !important;
        margin: 8px;
      }
      .ali-timeline-tab.v-tab--active {
        // background: var(--v-green-base);
      }
      .ali-timeline-tab-item {
        .ali-timeline-img {
          .ali-timeline-img-container {
            width: 200px;
            height: 200px;
            img {
              border-radius: 50%;
              object-fit: cover;
              object-position: center;
            }
          }
        }
        .ali-timeline-tab-content {
          background-color: transparent;
          .heading {
            font-size: 2rem;
          }
        }
      }
      .ali-timeline-tab:before {
        background-color: unset;
      }
      .ali-timeline-tab.v-tab--active {
        font-size: 1.6rem;
        width: 95px;
        height: 95px;
        max-width: 95px;
        max-height: 95px;
        min-width: 95px;
        min-height: 95px;
        border-width: 8px;
      }
    }
  }
}
</style>
<script>
export default {
  name: "AliTimeLine",
  props: {
    content: { type: String, required: true },
    color: { type: String, required: false, default: "green" }
  },
  data() {
    return {
      tab: null,
      contentParsed: null
    };
  },
  methods: {
    setBackgroundColor(element, color, important = false) {
      element.style.setProperty(
        "background-color",
        color,
        important ? "important" : ""
      );
    },

    updateColorStyles() {
      setTimeout(() => {
        const selector =
          ".v-tabs.ali-timeline-tabs .v-slide-group .v-slide-group__wrapper .v-slide-group__content .ali-timeline-tab.v-tab";
        const vTabs = this.$el.querySelectorAll(selector);
        const vTabsActives = this.$el.querySelectorAll(
          `${selector}.v-tab--active`
        );

        vTabs.forEach(el => {
          el.style.setProperty(
            "background-color",
            `var(--v-${this.color}-lighten2)`,
            "important"
          );
        });

        vTabsActives.forEach(el => {
          el.style.setProperty(
            "background-color",
            `var(--v-${this.color}-base)`,
            "important"
          );
        });
      }, 200);
    }
  },

  updated() {
    this.updateColorStyles();
  },

  mounted() {
    this.contentParsed = JSON.parse(this.content);
    this.updateColorStyles();
  }
};
</script>
