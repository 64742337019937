<template>
  <div class="service-autodiscovery-container">
    <p class="text-body-1 mb-2">
      {{ $t("addresses.autoDiscoveryHd.description") }}
    </p>

    <div class="d-flex">
      <VueGoogleAutocomplete
        v-if="googleEnabled"
        id="map"
        classname="service-discovery"
        class="rounded-sm"
        ref="address"
        :placeholder="$t('addresses.autoDiscoveryHd.placeholder')"
        v-on:placechanged="getAddressData"
        :enable-geolocation="true"
        country="it"
      >
      </VueGoogleAutocomplete>
      <v-tooltip left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            title="Geolocalizzami"
            class="rounded-sm primary ml-3"
            fab
            color="primary"
            icon
            small
            @click="geolocate"
            v-bind="attrs"
            v-on="on"
            ><v-icon>$location</v-icon></v-btn
          >
        </template>
        {{ $t("addresses.autoDiscoveryHd.buttonTooltip") }}
      </v-tooltip>
    </div>

    <v-card v-if="!loading" class="results mt-6">
      <v-card-text class="text-center">
        <b>{{ $t("addresses.autoDiscoveryHd.addressInserted") }}</b
        >{{ addressInserted }}
      </v-card-text>
      <v-card-title class="text-center justify-center">
        <v-alert type="success" v-if="homeDeliveryServiceAvailable">
          {{ $t("addresses.autoDiscoveryHd.addressOk") }}
        </v-alert>
        <v-alert type="error" v-else>
          {{ $t("addresses.autoDiscoveryHd.addressKo") }}
        </v-alert>
      </v-card-title>
      <v-card-actions
        v-if="homeDeliveryServiceAvailable"
        class="justify-center"
      >
        <v-btn
          color="primary"
          depressed
          min-width="170"
          :to="{ name: 'EditAddress' }"
        >
          <span>
            {{ $t("addresses.autoDiscoveryHd.addAddressButton") }}
          </span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>
<style lang="scss">
.service-autodiscovery-container {
  .service-discovery {
    background-color: white;
    border: 1px solid $border-color;
    line-height: 20px;
    padding: 8px;
    width: 100%;
    border-color: rgba(0, 0, 0, 0.42);
    border-radius: 4px;
  }
  .service-icon-wrapper {
    width: 50px !important;
    margin-right: 10px !important;
    @media #{map-get($display-breakpoints, 'xs-only')} {
      margin-right: 0 !important;
    }
  }
  .service-icon {
    height: 24px;
    margin-top: 6px;
    &.logo-home {
      height: 29px;
    }
    &.locker-logo {
      height: 31px;
    }
  }
  .add-address-icon {
    font-size: 14px;
    font-weight: bold;
    margin-right: 10px;
  }
  .auto-discovery-title,
  .auto-discovery-subtitle {
    white-space: normal !important;
  }
}
</style>
<script>
import VueGoogleAutocomplete from "vue-google-autocomplete";
import AddressService from "~/service/addressService";
import gmapsInit from "./gmaps";
import login from "~/mixins/login";
import { mapActions } from "vuex";
import GeoService from "~/service/geoService";
import includes from "lodash/includes";

var google;

export default {
  name: "ServiceAutodiscory",
  components: { VueGoogleAutocomplete },
  props: { additionalData: { type: Object, required: false } },
  mixins: [login],
  data() {
    return {
      loading: true,
      homeDeliveryServiceAvailable: null,
      googleEnabled: false,
      internalValue: {}
    };
  },
  computed: {
    addressInserted() {
      return this.$t(
        "navbar.address." + this.dataValidated.addressTypeId + ".format",
        this.dataValidated
      );
    }
  },
  methods: {
    ...mapActions({ setAddress: "cart/setAddress" }),
    async getAddressData(addressData, place) {
      this.loading = true;
      let vm = this;
      place.address_components.forEach(element => {
        if (element.types[0] == "administrative_area_level_2") {
          vm.internalValue.province = element.short_name;
          return;
        }
        if (element.types[0] == "country") {
          vm.internalValue.country = element.short_name;
          return;
        }
        if (element.types[0] == "administrative_area_level_3") {
          vm.internalValue.administrativeLevelArea3 = element.short_name;
          return;
        }
      });

      try {
        this.dataValidated = await AddressService.isValidAddress({
          country: vm.internalValue.country
            ? { iso: vm.internalValue.country }
            : undefined,
          city:
            addressData.locality || vm.internalValue.administrativeLevelArea3,
          postalcode: addressData.postal_code,
          address1: addressData.route,
          addressNumber: addressData.street_number,
          gpsLongitude: addressData.longitude,
          gpsLatitude: addressData.latitude,
          longitude: addressData.longitude,
          latitude: addressData.latitude,
          province: vm.internalValue.province
        });

        if (this.dataValidated && this.dataValidated.active === 1) {
          this.homeDeliveryServiceAvailable = true;
        } else {
          this.homeDeliveryServiceAvailable = false;
        }
      } catch (err) {
        this.homeDeliveryServiceAvailable = false;
      } finally {
        this.loading = false;
      }

      this.results = true;
    },

    getProvince(placeResult) {
      let area = placeResult.address_components.find(p => {
        return includes(p.types, "administrative_area_level_2");
      });
      return area["short_name"];
    },

    async handleAddressCreation(address) {
      if (await this.needLogin("service-autodiscovery")) {
        this.$router.push({
          name: "EditAddress",
          params: {
            newAddress: true,
            discoveredAddress: address
          }
        });
      }
      this.$emit("submit", false);
    },
    async handleWarehouseClick(addressType, addressId) {
      if (await this.needLogin("service-autodiscovery")) {
        await this.setAddress({
          addressType: addressType,
          addressId: addressId
        });
        if (await this.needTimeslot()) {
          this.$router.push({ name: "Home" });
        }
      }
    },
    geolocate() {
      let _this = this;
      if (navigator.geolocation) {
        document.getElementById("map").value = "";
        document.getElementById("map").placeholder =
          "Localizzazione in corso...";
        navigator.geolocation.getCurrentPosition(
          function(position) {
            var latlng = new google.maps.LatLng(
              position.coords.latitude,
              position.coords.longitude
            );
            var geocoder = new google.maps.Geocoder();
            geocoder.geocode({ location: latlng }, function(results, status) {
              if (status == google.maps.GeocoderStatus.OK) {
                var address = GeoService.decodeGoogleAddress(results[0]);
                address.latitude = address.latitude();
                address.longitude = address.longitude();
                var addressFormat = GeoService.composeAddress(address);
                document.getElementById("map").value = addressFormat;
                document.getElementById("map").placeholder =
                  "Scrivi il tuo indirizzo";
                _this.getAddressData(address);
              } else {
                console.log(
                  "Geocode was not successful for the following reason: "
                );
              }
            });
            // var ll = new google.maps.LatLng(
            //   position.coords.latitude,
            //   position.coords.longitude
            // );
            // vm.$refs.address.updateCoordinates(ll);
          },
          function(err) {
            console.log(err);
            alert(
              "è necessario abilitare la localizzazione per utilizzare questa funzione"
            );
          },
          { enableHighAccuracy: false, maximumAge: Infinity }
        );
      }

      // this.$refs.address.geolocate();
    }
  },
  async mounted() {
    try {
      google = await gmapsInit(global.config.google_api_key);
      if (google) {
        this.googleEnabled = true;
      }
    } catch (error) {
      throw new Error("Error Loading GMAPS");
    }
  }
};
</script>
