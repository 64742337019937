<template>
  <div :id="container_id" class="sf-flyer-container"></div>
</template>
<style lang="scss">
.sf-flyer-container {
  min-height: calc(100vh - 210px);
  iframe {
    width: 100%;
    height: 100%;
    min-height: calc(100vh - 210px) !important;
  }
}
</style>
<script>
export default {
  name: "FlyerContainer",
  props: {
    id: {
      type: String,
      required: false,
      default: "5f8ff474-71bc-4fe0-9fc8-09ceac1f4773"
    },
    warehouseId: { type: Number, required: true },
    sf_flyer_id: { type: String, required: false },
    sf_retailer_id: { type: String, required: false, default: "259" },
    sf_store_id: { type: String, required: false },
    list: { type: Boolean, required: false, default: true },
    //eheight: { type: String, required: false, default: "400px" },
    container_id: {
      type: String,
      required: false,
      default: "volantino_iframe_container"
    }
  },
  data() {
    return {
      country: "it_it",
      sf_section: this.list ? "flyers" : "flyer"
    };
  },
  mounted() {
    const script = document.createElement("script");
    script.src =
      "https://viewer-whitelabel.shopfully.cloud/scripts/v1/init.min.js";
    script.async = true;
    const self = this;
    script.onload = function() {
      window.SFviewer({
        country: self.country,
        id: self.id,
        container_id: self.container_id,
        sf_retailer_id: self.sf_retailer_id,
        sf_section: self.list ? "flyers" : "flyer",
        // props values:
        sf_store_id: self.warehouseId,
        sf_flyer_id: self.sf_flyer_id
      });
    };
    document.head.appendChild(script);
  }
};
</script>
