<template>
  <v-card
    tile
    elevation="0"
    outlined
    class="warehouse-card d-flex flex-column flex-md-row h-100"
  >
    <v-img class="img-pdv" alt="Immagine punto vendita" :src="srcImage"></v-img>
    <div class="w-100 d-flex flex-column text-md-left align-md-start">
      <v-card-title>
        <h2 class="warehouse-card-title">
          {{ title }}
        </h2>
      </v-card-title>
      <v-card-text class="text-body-2 warehouse-card-desc" v-html="text">
      </v-card-text>
      <v-card-actions v-if="buttonLink" class="w-100">
        <v-btn
          large
          color="secondary"
          class="px-6 w-100"
          depressed
          :to="buttonLink"
        >
          {{ buttonText }}
        </v-btn>
      </v-card-actions>
    </div>
  </v-card>
</template>
<style lang="scss">
.warehouse-card {
  .img-pdv {
    max-width: 33%;
    @media #{map-get($display-breakpoints, 'sm-and-down')} {
      max-width: none;
    }
  }
  .warehouse-card-title {
    word-break: break-word;
    color: var(--v-primary-base);
    margin-top: -8px;
    margin-bottom: -8px;
  }
  .warehouse-card-desc {
    color: $text-color;
    margin-bottom: -8px;
  }
}
</style>
<script>
import AddressService from "~/service/addressService";

import get from "lodash/get";

export default {
  name: "WarehouseCard",
  props: {
    srcImage: { type: String },
    title: { type: String },
    text: { type: String },
    buttonLink: { type: String },
    buttonText: { type: String }
  },
  data() {
    return {
      warehouseList: []
    };
  },
  methods: {
    getImagePdvSrv(warehouse) {
      return get(warehouse, "metaData.warehouse_info.IMAGE_WAREHOUSE");
    },
    async findWarehouse() {
      let data = await AddressService.findWarehouse({
        province_id: this.provinceIdParsed
      });
      this.warehouseList = data.warehouses;
    }
  },
  mounted() {
    if (this.provinceId != undefined) {
      this.provinceIdParsed = parseInt(this.provinceId);
    }
    this.findWarehouse();
  }
};
</script>
