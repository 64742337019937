import { render, staticRenderFns } from "./SocialShare.vue?vue&type=template&id=12d34ead&"
import script from "./SocialShare.vue?vue&type=script&lang=js&"
export * from "./SocialShare.vue?vue&type=script&lang=js&"
import style0 from "./SocialShare.vue?vue&type=style&index=0&id=12d34ead&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports