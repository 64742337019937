var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"we-love-trees-map",on:{"click":_vm.clicked}},[_c('div',{staticClass:"wlt-categories d-flex flex-wrap my-5"},_vm._l((_vm.categories),function(category){return _c('div',{key:category.id,staticClass:"wlt-category d-flex my-2 mr-6",on:{"click":function($event){_vm.categoryId = category.id}}},[_c('v-img',{staticClass:"mr-2",attrs:{"contain":"","width":"16","max-width":"16","src":`/img_layout/map/${category.marker}.png`}}),_c('div',{staticClass:"category-name font-weight-bold text-uppercase mr-2",style:(`color:${category.colore};`),domProps:{"innerHTML":_vm._s(category.name)}})],1)}),0),_c('GmapMap',{ref:"mapRef",style:({ width: _vm.mapWidth, height: _vm.mapHeight }),attrs:{"center":{
      lat: 45.410119194768654,
      lng: 11.881289090441028
    },"zoom":10,"options":{
      zoomControl: true,
      mapTypeControl: false,
      scaleControl: false,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: false,
      disableDefaultUI: false,
      draggable: true
    }}},[_vm._l((_vm.filteredWltPosts),function(wltPost){return _c('GmapMarker',{key:wltPost.id,ref:'wlt_marker' + wltPost.id,refInFor:true,staticClass:"map-pointer",attrs:{"position":_vm.google &&
          new _vm.google.maps.LatLng(wltPost.latitudine, wltPost.longitudine),"clickable":true,"icon":`/img_layout/map/${wltPost?.wlt_cat?.marker}.png`},on:{"click":function($event){return _vm.toggleInfoWindow(wltPost)}}})}),_c('gmap-info-window',{attrs:{"options":_vm.infoOptions,"position":_vm.infoWindowPos,"opened":_vm.infoWinOpen},on:{"closeclick":function($event){$event.stopPropagation();_vm.infoWinOpen = false}}},[_vm._v(" "+_vm._s(_vm.selectedWlt)+" ")])],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }