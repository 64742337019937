<template>
  <div class="wordpress-slider-container slider banner-slider">
    <swiper :options="swiperOption" ref="swiperRef">
      <swiper-slide
        v-for="slide in slides"
        :key="slide.menu_order"
        :ref="swiperRef"
        :class="design === 'design-8' ? 'ma-0' : null"
      >
        <div v-if="design === 'design-7' || design === 'card'">
          <v-card
            :href="slide.link"
            elevation="0"
            :class="'ma-2 text-center ' + sliderId"
            outlined
          >
            <img
              :src="slide.featured_image_url"
              style="display:block;margin:0 auto;"
            />
            <v-card-text
              v-html="slide.title"
              class="swiper-slider-overlay text-center"
            ></v-card-text>
          </v-card>
        </div>
        <div v-else-if="design === 'design-8'">
          <div
            class="wpsisac-image-slide slick-slide slick-cloned slick-active"
          >
            <div class="container-point">
              <div class="timeline-point"></div>
              <div class="timeline-line"></div>
            </div>
            <div class="wpsisac-image-slide-wrap">
              <div class="wpsisac-slider-overlay">
                <div class="container-point">
                  <div class="timeline-point"></div>
                  <div class="timeline-line"></div>
                </div>
                <div class="wpsisac-slider-content">
                  <div class="wpsisac-slider-short-content">
                    <div v-html="slide.title"></div>
                    <div id="" class="after-content">
                      <div class="container "></div>
                      <!-- #xsbf-after-content -->
                    </div>
                    <!-- .container -->
                  </div>
                </div>
              </div>

              <img
                class="swiper-slide-img"
                style="max-width:100%;"
                :src="slide.featured_image_url"
              />
            </div>
          </div>
        </div>
        <div v-else-if="design === 'ali-design'">
          <div
            :href="slide.link"
            elevation="0"
            :class="'ma-2 ' + sliderId"
            outlined
          >
            <v-img :src="slide.featured_image_url" class="ma-0" />
            <v-card-text
              v-html="slide.title"
              class="swiper-slider-overlay px-1"
            ></v-card-text>
          </div>
        </div>
        <div v-else>
          <a v-if="slide.link" :href="slide.link">
            <img
              class="swiper-slide-img"
              style="max-width:100%;"
              :src="slide.featured_image_url"
            />
          </a>
          <img
            v-else
            class="swiper-slide-img"
            style="max-width:100%;"
            :src="slide.featured_image_url"
          />
        </div>
      </swiper-slide>
    </swiper>
    <div
      v-if="showBullets && slides.length > 1"
      :id="`banner-pagination-${paginationClass}`"
      :class="`swiper-pagination banner-pagination-${paginationClass}`"
    ></div>
    <!-- <div
      v-if="showArrows && slides.length > 1"
      :id="`banner-slider-prev-${paginationClass}`"
      :class="`swiper-button-prev banner-slider-prev-${paginationClass}`"
    ></div>
    <div
      v-if="showArrows && slides.length > 1"
      :id="`banner-slider-next-${paginationClass}`"
      :class="`swiper-button-next banner-slider-next-${paginationClass}`"
    ></div> -->
  </div>
</template>
<style lang="scss">
.wordpress-slider-container {
  .swiper-slide-img {
    width: 100%;
  }
}
</style>
<script>
import deliveryReactive from "~/mixins/deliveryReactive";
import CMSService from "~/service/cmService";
import VRuntimeTemplate from "v-runtime-template";
import { VBtn } from "vuetify/lib";
export default {
  name: "WordPressSlider",
  props: {
    sliderId: { type: String, required: true },
    paginationClass: { type: String, default: "swiper-pagination-banner" },
    cols: { default: 1 },
    sm: { default: 2 },
    md: { default: 3 },
    lg: { default: 3 },
    xl: { default: 4 },
    // FOR BACKWARD COMPATIBILITY
    dots: { type: String, default: "true" },
    arrows: { type: String, default: "true" },
    autoplay: { type: String, default: "true" },
    slidestoshow: { type: String, default: null },
    design: { type: String, default: null }
  },
  mixins: [deliveryReactive],
  data() {
    return {
      slides: [],
      swiperRef: null,
      showArrows: true,
      showBullets: true,
      swiperOption: {
        spaceBetween: 20,
        autoplay: {
          disableOnInteraction: true
        },
        watchOverflow: true,
        loop: false,
        pagination: {
          el: `.banner-pagination-${this.paginationClass}`,
          clickable: true
        },
        navigation: {
          prevEl: `.banner-slider-prev-${this.paginationClass}`,
          nextEl: `.banner-slider-next-${this.paginationClass}`
        },
        breakpoints: {
          0: {
            slidesPerView: this.cols
          },
          600: {
            slidesPerView: this.sm
          },
          960: {
            slidesPerView: this.md
          },
          1264: {
            slidesPerView: this.slidestoshow || this.lg
          },
          1904: {
            slidesPerView: this.slidestoshow || this.xl || this.lg
          }
        }
      }
    };
  },
  /* eslint-disable vue/no-unused-components */
  components: {
    VRuntimeTemplate,
    VBtn
  },
  methods: {
    reload() {
      if (this.$refs.swiperRef.swiperInstance) {
        this.$refs.swiperRef.swiperInstance.slideTo(0);
      }
    }
  },
  async created() {
    const res = await CMSService.getSliderBySlug(this.sliderId);
    this.slides = res;

    if (this.dots === "false") {
      this.showBullets = false;
    }

    if (this.arrows === "false") {
      this.showArrows = false;
    }
  }
};
</script>
