<template>
  <v-card
    class="green wlt-info-card alt"
    height="220"
    :to="{
      name: 'WeLoveTreesList'
    }"
  >
    <v-card-title class="content text1 mb-n5">{{
      $t("weLoveTrees.discover.title")
    }}</v-card-title>
    <v-card-title class="content text2 mb-n5">{{
      $t("weLoveTrees.discover.subtitle")
    }}</v-card-title>
    <v-card-title class="content text3">{{
      $t("weLoveTrees.discover.text")
    }}</v-card-title>
    <v-card-actions class="justify-end to-bottom white--text font-weight-bold">
      <span>{{ $t("weLoveTrees.discover.cta") }}</span>
      <v-icon color="white">$chevronRight</v-icon>
    </v-card-actions>
  </v-card>
</template>
<style lang="scss">
.we-love-trees-detail {
  .vue-map-container .vue-map {
    border: 1px solid var(--v-grey-lighten1);
    border-radius: 12px;
  }
}
.wlt-info-card {
  .content {
    color: #ffffff !important;
  }
  a {
    text-decoration: none !important;
  }
  .title {
    font-size: 24px !important;
  }
  .subtitle {
    font-size: 14px !important;
  }
  .points {
    font-size: 30px;
  }
}
.wlt-info-card.alt {
  .text1 {
    font-size: 28px;
  }
  .text2 {
    font-size: 18px;
  }
  .text3 {
    font-size: 27px;
  }
}
.to-bottom {
  position: absolute;
  bottom: 0;
  right: 0;
}
</style>
<script>
export default {
  name: "InitiativesCard"
};
</script>
