<template>
  <div class="nice-swiper" v-if="slides">
    <swiper :options="swiperOptions">
      <swiper-slide v-for="image in slides" :key="image">
        <v-img :src="image" class="w-100" height="auto" contain />
      </swiper-slide>
    </swiper>
    <div class="nice-swiper-navigation d-flex justify-end">
      <div
        class="swiper-button-prev"
        :id="`nice-swiper-prev-${paginationClass}`"
      >
        <!-- <v-icon>$chevronLeft</v-icon> -->
      </div>
      <div
        class="swiper-button-next"
        :id="`nice-swiper-next-${paginationClass}`"
      >
        <!-- <v-icon>$chevronRight</v-icon> -->
      </div>
    </div>
  </div>
</template>
<style scoped lang="scss">
.nice-swiper {
  .nice-swiper-navigation {
    margin-top: 50px;
    margin-bottom: -20px;
  }
  .swiper-button-prev,
  .swiper-button-next {
    cursor: pointer;
    transition: all 0.3s ease;
    color: red;
  }

  .swiper-button-prev {
    position: relative;
    padding: 26px;
    border-radius: 100%;
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.1);
    margin-right: 60px;
  }

  .swiper-button-next {
    position: relative;
    padding: 26px;
    border-radius: 100%;
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.1);
  }

  .swiper-button-prev::after,
  .swiper-button-next::after {
    font-size: 2rem;
    color: #000000;
  }

  .swiper-button-prev:hover,
  .swiper-button-next:hover {
  }
}
</style>

<script>
export default {
  name: "NiceSwiper",
  props: {
    images: {
      type: String,
      required: true
    },
    paginationClass: { type: String, required: true }
  },
  data() {
    return {
      slides: null,
      swiperOptions: {
        slidesPerGroup: 1,
        spaceBetween: 20,
        breakpoints: {
          0: {
            slidesPerView: 1.3
          },
          600: {
            slidesPerView: 2.3
          },
          960: {
            slidesPerView: 2.3
          },
          1264: {
            slidesPerView: 2.3
          },
          1900: {
            slidesPerView: 3.3
          }
        },
        navigation: {
          prevEl: `#nice-swiper-prev-${this.paginationClass}`,
          nextEl: `#nice-swiper-next-${this.paginationClass}`
        }
      }
    };
  },
  mounted() {
    this.slides = this.images.split(",");
  }
};
</script>
