<template>
  <div v-if="posts" class="w-100 slider-main-wrapper">
    <swiper :options="swiperOption" ref="swiperRef" class="w-100 h-100">
      <swiper-slide v-if="showInitiatives">
        <InitiativesCard class="my-2"></InitiativesCard>
      </swiper-slide>
      <swiper-slide v-for="post in posts" :key="post.id">
        <PostCard
          :post="post"
          :type="isWeLoveTrees ? 'we_love_trees' : 'post'"
        ></PostCard>
      </swiper-slide>
    </swiper>
    <div
      v-if="showBullets && posts.length > 1"
      :id="`card-slider-pagination-${paginationClass}`"
      class="swiper-pagination"
    ></div>
    <div
      v-if="showArrows && posts.length > 1 && $vuetify.breakpoint.mdAndUp"
      :id="`card-slider-slider-prev-${paginationClass}`"
      class="swiper-button-prev"
    ></div>
    <div
      v-if="showArrows && posts.length > 1 && $vuetify.breakpoint.mdAndUp"
      :id="`card-slider-slider-next-${paginationClass}`"
      class="swiper-button-next"
    ></div>
  </div>
</template>
<style scoped lang="scss">
.slider-main-wrapper {
  position: relative;
  .swiper-button-next,
  .swiper-button-prev {
    z-index: 1;
  }
}
</style>
<script>
import CMService from "~/service/cmService";
import InitiativesCard from "@/components/wordpress/InitiativesCard.vue";
import PostCard from "@/components/wordpress/PostCard.vue";
export default {
  name: "CardSlider",
  components: { PostCard, InitiativesCard },
  props: {
    showInitiatives: { type: Boolean, required: false, default: true },
    isWeLoveTrees: { type: Boolean, required: false, default: false },
    paginationClass: { type: String, required: false },
    showArrows: { type: Boolean, required: false, default: true },
    showBullets: { type: Boolean, required: false, default: true },
    cat: { type: String, required: false, default: null },
    is_news: { type: Boolean, required: false, default: false }
  },
  data() {
    return {
      posts: null,
      swiperOption: {
        slidesPerView: 1.2,
        spaceBetween: 10,
        autoHeight: true,
        watchOverflow: true,
        keyboard: {
          enabled: true
        },
        pagination: {
          el: `#card-slider-pagination-${this.paginationClass}`,
          clickable: true
        },
        navigation: {
          prevEl: `#card-slider-slider-prev-${this.paginationClass}`,
          nextEl: `#card-slider-slider-next-${this.paginationClass}`
        },
        breakpoints: {
          600: {
            slidesPerView: 2.2,
            slidesPerGroup: 2,
            spaceBetween: 15
          },
          960: {
            slidesPerView: 3.3,
            slidesPerGroup: 3
          },
          1264: {
            slidesPerView: 4.4,
            spaceBetween: 20,
            slidesPerGroup: 4
          }
        }
      },
      postType: "we_love_trees"
    };
  },
  mounted() {
    this.fetchPosts();
  },
  methods: {
    async fetchPosts() {
      let params = [];
      params.push({
        filterName: "page",
        filterValue: this.page
      });
      console.log("CAT:", this.cat);
      if (this.cat != null) {
        params.push({
          filterName: "categories",
          filterValue: this.cat
        });
      }

      if (this.is_news) {
        this.postType = "posts";
      }

      const res = await CMService.getCustomPostByFilters(
        this.postType,
        params,
        8
      );
      if (res && res.data && res.data.length) {
        this.pager = res.page;
        this.posts = res.data;
      } else {
        this.posts = null;
      }
    }
  }
};
</script>
