<template>
  <div class="we-love-trees-map" @click="clicked">
    <div class="wlt-categories d-flex flex-wrap my-5">
      <div
        class="wlt-category d-flex my-2 mr-6"
        v-for="category in categories"
        :key="category.id"
        @click="categoryId = category.id"
      >
        <v-img
          contain
          width="16"
          max-width="16"
          class="mr-2"
          :src="`/img_layout/map/${category.marker}.png`"
        ></v-img>
        <div
          v-html="category.name"
          class="category-name font-weight-bold text-uppercase mr-2"
          :style="`color:${category.colore};`"
        ></div>
      </div>
    </div>
    <GmapMap
      ref="mapRef"
      :center="{
        lat: 45.410119194768654,
        lng: 11.881289090441028
      }"
      :zoom="10"
      :style="{ width: mapWidth, height: mapHeight }"
      :options="{
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
        disableDefaultUI: false,
        draggable: true
      }"
    >
      <GmapMarker
        v-for="wltPost in filteredWltPosts"
        :key="wltPost.id"
        :ref="'wlt_marker' + wltPost.id"
        class="map-pointer"
        :position="
          google &&
            new google.maps.LatLng(wltPost.latitudine, wltPost.longitudine)
        "
        :clickable="true"
        :icon="`/img_layout/map/${wltPost?.wlt_cat?.marker}.png`"
        @click="toggleInfoWindow(wltPost)"
      />
      <gmap-info-window
        :options="infoOptions"
        :position="infoWindowPos"
        :opened="infoWinOpen"
        @closeclick.stop="infoWinOpen = false"
      >
        {{ selectedWlt }}
      </gmap-info-window>
    </GmapMap>
  </div>
</template>
<style lang="scss">
.we-love-trees-map {
  position: relative;
  .wlt-categories {
    .wlt-category {
      cursor: pointer;
      .category-name {
        font-size: 0.8rem;
      }
    }
    // position: absolute;
    // top: 0;
    // left: 0;
    // z-index: 1;
  }
}
.map-pointer {
  cursor: pointer;
}

.infoWindowDialog,
.gm-style .gm-style-iw {
  min-width: 180px !important;
  min-height: 80px !important;
  .gmap-city {
    font-size: 12px;
  }
  .gmap-address {
    font-size: 16px;
  }
  .cta {
    position: absolute;
    right: 8px;
    bottom: 8px;
    text-decoration: none;
    color: inherit;
  }
}
</style>

<script>
import CMService from "~/service/cmService";
import { gmapApi } from "vue2-google-maps";
import clickHandler from "~/mixins/clickHandler";

export default {
  name: "WeLoveTreesMap",
  mixins: [clickHandler],
  data() {
    return {
      wltPosts: [],
      categories: null,
      categoryId: null,
      infoOptions: {
        content: "",
        //optional: offset infowindow so it visually sits nicely on top of our marker
        pixelOffset: {
          width: 0,
          height: -35
        }
      },
      infoWindowPos: null,
      infoWinOpen: false,
      selectedWlt: null
    };
  },
  methods: {
    async getWltPosts(page = 1) {
      try {
        let params = [
          {
            filterName: "page",
            filterValue: page || 1
          }
        ];
        let res = await CMService.getCustomPostByFilters(
          "we_love_trees",
          params,
          100
        );

        if (res.data) {
          this.wltPosts.push(...res.data);
          if (res.page.totPages >= page) {
            await this.getWltPosts(++page);
          }
        }
      } catch (error) {
        console.error(error);
      }
    },
    async fetchCategories() {
      const categories = await CMService.getCustomPostByFilters(
        "categoria_wlt",
        null,
        100
      );
      this.categories = categories.data.filter(cat => cat.parent === 73);
    },
    toggleInfoWindow(wlt) {
      this.infoWindowPos = {
        lat: parseFloat(wlt.latitudine),
        lng: parseFloat(wlt.longitudine)
      };
      this.infoOptions.content = `
        <div class='infoWindowDialog'>
          <p class='gmap-city'>${wlt.citta}</p>`;
      if (wlt.indirizzo != null) {
        this.infoOptions.content += `          <div class='gmap-info' style='color:${wlt.wlt_cat.colore};'>
            <div class='gmap-address'>${wlt.indirizzo}</div>
          </div>`;
      }
      this.infoOptions.content += "</div>";

      //check if its the same marker that was selected if yes toggle
      if (this.selectedWlt && this.selectedWlt.id == wlt.id) {
        this.infoWinOpen = !this.infoWinOpen;
      }

      //if different marker set infowindow to open and reset current marker index
      else {
        this.infoWinOpen = true;
        this.selectedWlt = wlt;
      }
    }
  },
  computed: {
    google: gmapApi,
    mapWidth() {
      return "auto";
    },
    mapHeight() {
      return this.$vuetify.breakpoint.xsOnly ? "400px" : "700px";
    },
    filteredWltPosts() {
      if (this.categoryId) {
        return this.wltPosts.filter(wlt =>
          wlt.categoria_wlt.includes(Number(this.categoryId))
        );
      } else {
        return this.wltPosts;
      }
    }
  },
  async created() {
    await this.getWltPosts();
    await this.fetchCategories();
  }
};
</script>
