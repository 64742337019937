<template>
  <div class="tree_animation rounded-md pa-4">
    <!-- <h2 class="text-center">{{ $t("weLoveTrees.animationTree.title") }}</h2> -->
    <img
      :src="`/img_layout/we_love_trees/steps/${step}.png`"
      class="tree_step"
    />
  </div>
</template>
<script>
import customService from "@/service/customService";

export default {
  data() {
    return {
      currentStep: 0,
      finalStep: 1,
      intervalId: null,
      step: 0
    };
  },
  props: {
    delay: { type: Number, required: false, default: 300 }
  },
  methods: {
    nextSlide() {
      this.currentStep++;
      if (this.currentStep === this.finalStep) {
        clearInterval(this.intervalId);
      }
    },
    pointsToSteps(points) {
      let steps = Math.round(points / 100);
      if (steps <= 0) {
        steps = 1;
      } else if (steps >= 20) {
        steps = 20;
      }
      this.step = steps;
      return steps;
    },
    async treesPlanted() {
      let result = await customService.treesPlanted();
      console.log(result);
      if (result) {
        this.finalStep = this.pointsToSteps(result);
      }
    }
  },
  mounted() {
    if (this.finalStep > 20) {
      this.finalStep = 20;
    }
    this.intervalId = setInterval(this.nextSlide, this.delay);
  },
  created() {
    this.treesPlanted();
  }
};
</script>
